import mixpanel from "mixpanel-browser";

const eventTrackingToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

export function isEventTrackingRequired() {
  if (eventTrackingToken && process.env.NEXT_PUBLIC_ENABLE_MIXPANEL === "1") {
    return true;
  }
  return false;
}

if (isEventTrackingRequired()) {
  mixpanel.init(`${eventTrackingToken}`);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEventData {}
export class EventTracker {
  static track(event: string, value: IEventData = {}) {
    if (isEventTrackingRequired()) {
      mixpanel.track(event, value);
    }
  }

  static setUser(distinct_id: string) {
    if (isEventTrackingRequired()) {
      mixpanel.identify(distinct_id);
    }
  }

  static resetUser() {
    if (isEventTrackingRequired()) {
      mixpanel.reset();
    }
  }
}
